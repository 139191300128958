/* imports*/
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Poppins:wght@300;400;500;600&display=swap');
*{
    /* style resets*/
    margin:0;
    padding:0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

/* main page style*/
:root{
    /* css variables */
    /* for easy to update the page colors */

    /* colors */
    --color-bg: #ffe600;
    --color-bg-variant: #aeaeae;
    --color-primary: #003366;
    --color-primary-variant: #003153;
    --color-white:#fff;
    --color-black:rgb(0, 0, 0);
    --color-light:rgb(255, 255, 255);

    /* container */
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;

    /* transition */
    --transition: all 400ms ease;

}

html{
    /* scrolling animation */
    scroll-behavior: smooth;
}

/* don't like the scrollbar on windows so might as well disable for good */
::-webkit-scrollbar{
    display: none;
}

body{
    /* fonts */
    font-family: 'Permanent Marker', cursive;
    font-family: 'Poppins', sans-serif;
    color: var(--color-black);
    line-height: 1.7;
    /* background */
    background: var(--color-bg);
    background-image: url(../src/assets/textured-stripes.png);

}

/* GENERAL STYLES */
.container{
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5{
    font-weight: 500;

}

h1{
    font-size: 2.5rem;
}

section{
    margin-top: 8rem;
}
section > h2, section > h5{
    text-align: center;
    color: var(--color-light);
}
section >h2{
    color: var(--color-primary);
    margin-bottom: 3rem;
}
.text-light{
    color: var(--color-light);
}
a{
    color: var(--color-primary);
    transition: var(--transition);
}
a:hover{
    color: var(--color-black);
}

.btn{
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-black);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary{
    background: var(--color-primary);
    color: var(--color-bg);
}
img{
    display: block;
    width: 100%;
    object-fit: cover;
}

/* MEDIA QUERIES */

/* MD DEVICES */
@media screen and (max-width:1024px) {
    .container{
        width: var(--container-width-md);
    }
    section{
        margin-top: 6rem;
    }
}

/* SM DEVICES */
@media screen and (max-width:600px) {
    .container{
        width: var(--container-width-sm);
    }
    section > h2{
        margin-bottom: 2rem;
    }
}