/* Style header */
header{
    
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}

/* Style CTA */
.cta{
    /* push down */
    margin-top: 2.5rem;
    /* give gap  */
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}
/* Style social bar */
.header__social{
    display: flex;
    /* make a column */
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

/* line after */
.header__social::after{
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* Style image me */
.me{
    
    /* size */
    width: 22rem;
    height: 30rem;
    /* position */
    position:absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    /* shape */
    border-radius: 12rem 12rem 0 0;
    overflow: visible;
    padding: 5rem 1.5rem 1.5rem 1.5rem;

}
/* Style scroll down */
.scroll__down{
    position:absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font: 300;
    font-size: 0.9rem;
}

/* MEDIA QUERIES */

/* MD DEVICES */
@media screen and (max-width:1024px) {

   header{
    height: 68vh;

   }
}

/* SM DEVICES */
@media screen and (max-width:600px) {

    header{

        height: 100vh;
    }

    .header__social,.scroll__down{
        display: none;
    }
}