.experience__container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}
.experience__frontend{
    background-color: var(--color-primary);
    color: white;
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;

}
.experience__backend{
    background-color: var(--color-bg);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    border-color: var(--color-primary);
    
}
.experience__container > div{
    box-shadow: 0px 10px 20px -5px var(--color-black);
}
.experience__container > div:hover{
    background: var(--color-black);
    color: var(--color-bg);
    border-color: transparent;
}
.experience__container > div > h3{
    text-align: center;
    margin-bottom: 2rem;
}
.experience__content{
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    text-align: center;
}
/* MEDIA QUERIES */

/* MD DEVICES */
@media screen and (max-width:1024px) {
    .experience__container{
        grid-template-columns: 1fr;
    }
    .experience__container > div{
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }
    .experience__content{
        padding: 1rem;
    }
 }
 
 /* SM DEVICES */
 @media screen and (max-width:600px) {
    .experience__container{
        gap: 4rem;
    }
    .experience__container > div{
        width: 100%;
        padding: 2rem 1rem;
    }

 }
