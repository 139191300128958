:root{
    /* more color variables */
    --red: hsl(0, 78%, 62%);
    --cyan: hsl(180, 62%, 55%);
    --orange: hsl(34, 97%, 64%);
    --blue: hsl(212, 86%, 64%);
    --varyDarkBlue: hsl(234, 12%, 34%);
    --grayishBlue: hsl(229, 6%, 66%);
    --veryLightGray: hsl(0, 0%, 98%);
}
/* container style */
.about__container{
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
    
}

.about__me{
    width: 100%;
    background: linear-gradient(
        45deg,
        transparent,
        var(--color-primary),
        transparent
    );
    aspect-ratio: 1/1;
    border-radius: 2rem;
    display: grid;
    place-items: center;
}
.about__me-img{
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}
.about__me-img:hover{
    transform: rotate(0deg);
}
/* right side of the web */

/* cards grid */
.about__cards{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1.5rem;
}
/* card styles */
.about__card{
    background-color: var(--color-white);
    border: 1px solid transparent;
    border-radius: 1rem;
    box-shadow: 0px 10px 20px -5px var(--grayishBlue);
    padding: 2rem;
    text-align: center;
}
.about__card:hover{
    background: var(--color-primary);
    color: white;
    border-color: var(--color-primary-variant);
    transition: var(--transition);
    cursor: default;
}
.about__card h5{
    font-size: 0.95rem;
}
.about__card small{
    font-size: 0.7rem;
}

/* card top */
.red {
    border-top: 3px solid var(--red);
}
.blue {
    border-top: 3px solid var(--blue);
}
.orange {
    border-top: 3px solid var(--orange);
}

/* intro p styles */
p{ 
    margin: 2rem 0 2.6rem;

    
}
/* MEDIA QUERIES */

/* MD DEVICES */
@media screen and (max-width:1024px) {
    .about__container{
        grid-template-columns: 1fr;
        gap: 0;
    }
    .about__me{
        width: 50%;
        margin: 2rem auto 4rem;
    }
    .about__content p{
        margin: 1rem 0 1.5rem;
    }

 }
 
 /* SM DEVICES */
 @media screen and (max-width:600px) {
    .about__me{
        width: 65%;
        margin: 0 auto 3rem;
    }
    .about__cards{
        grid-template-columns: 1fr,1fr;
        gap: 1rem;
    }
    .about__content p{
        margin: 1.5rem 0;
    }

 }
